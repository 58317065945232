<template>
    <div>
        <!--WORK DESCRIPTION -->
        <div class="container desc">
            <div class="row" id="work">
                <div class="col-lg-2 col-lg-offset-1">
                    <h5><b>WORK</b></h5>
                </div>

                <ResumeEntry id="0" title="Systems Engineer" 
                v-bind:location="Link('https://www.5g-mobix.com/', 'TU/e 5G-MOBIX team') + ', Eindhoven, Netherlands'" 
                v-bind:text="'· Research project.'"
                time="SEPTEMBER 2020 - PRESENT" duration="part-time"/>

                <ResumeEntry id="1" title="Programmer Intern" 
                v-bind:location="Link('https://www.taleworlds.com/', 'TaleWorlds Entertainment') + ', Ankara, Turkey'" 
                v-bind:text="'\
                · Programmed and designed on the ' + Link('https://youtu.be/M_PSRDwORDQ', 'Mount and Blade 2 Bannerlord') + ' project <br>\
                · Worked in the combat team, part of a larger team of around 100 developers <br>\
                · Mainly C++ and some C#. Working on, and in the new Mount & Blade II engine'"
                time="SEPTEMBER 2017 - FEBRUARY 2018" duration="6 months, full-time"/>
                
                <ResumeEntry id="2" title="Programmer" 
                v-bind:location="Link('https://www.gainplaystudio.com/', 'GainPlay Studio') + ', Utrecht, Netherlands'" 
                v-bind:text="'\
                · Programmed and designed the applied game ' + Link('http://www.digestinn.com/', 'DigestInn') + ' in a team of 10 <br>\
                · Updates for ' + Link('https://play.google.com/store/apps/details?id=com.GainPlayStudio.KwestieKat', 'WZW Zelfregie App ') + '<br>\
                · C#, Unity, Java, PHP, MySql and encryption'" 
                time="JANUARY 2017 - SEPTEMBER 2017" duration="9 months, full-time"/>

                <ResumeEntry id="3" title="Computer Store Sales, Management & Repairs" 
                v-bind:location="Link('https://www.mkshop.nl/', 'MKComputerShop') + ', Mijdrecht, Netherlands'" 
                v-bind:text="'\
                · Customer & corporate sales<br>\
                · Hardware and software repairs <br>\
                · Retours, orders and everything else needed to run the store'" 
                time="NOVEMBER 2013 - SEPTEMBER 2017" duration="3 years and 11 months, part-time"/>

                <ResumeEntry id="4" title="Specialist stringed instruments" 
                v-bind:location="Link('https://www.bax-shop.nl/', 'Bax Music') + ', Amsterdam, Netherlands'" 
                v-bind:text="'\
                · Customer & corporate sales <br>\
                · Instrument repairs <br>\
                · Retours, orders and other tasks'" 
                time="DECEMBER 2016 - SEPTEMBER 2017" duration="10 months, part-time"/>

                <ResumeEntry id="5" title="Event work" 
                v-bind:location="Link('https://yousician.com/', 'Yousician') + ', Helsinki, Finland'" 
                v-bind:text="'\
                · Represented Yousician on game development events, discussing and presenting how the products (Yousician and GuitarTuna) work with other developers'" 
                time="JUNE 2017" duration=""/>

                <!-- <ResumeEntry id="" title="" 
                v-bind:location="" 
                v-bind:text="'\
                '" 
                time="" duration=""/> -->
            </div>
            <br>
            <hr>
        </div>

        <!--EDUCATION DESCRIPTION -->
        <div class="container desc">
            <div class="row" id="education">
                <div class="col-lg-2 col-lg-offset-1">
                    <h5><b>EDUCATION</b></h5>
                </div>

                <ResumeEntry id="0" title="Computer Science and Engineering" 
                v-bind:location="Link('https://www.tue.nl/en/', 'Eindhoven University of Technology') + ', Netherlands'" 
                text="QS 2019 worldwide top 100 university." 
                time="AUGUST 2019 - CURRENT" duration="3 year course"/>

                <ResumeEntry id="1" title="International Game Architecture and Design" 
                v-bind:location="Link('https://www.buas.nl/en', 'Breda University') + ', Netherlands'" 
                v-bind:text="
                'Rookies 2019 ' + Link('https://discover.therookies.co/schools/best-game-design-schools-in-the-world/', '4th best') + ' game design university worldwide.' + '<br>' +
                'Finished propaedeutic with avg. grade 8/10 (' + Link('https://www.studyinholland.nl/life-in-holland/dutch-grading-system', 'grading system') + ').'" 
                time="AUGUST 2018 - JULY 2019" duration="1 year course (propaedeutic)"/>

                <ResumeEntry id="2" title="Game Developer" 
                v-bind:location="Link('https://glu.nl/', 'Grafisch Lyceum Utrecht') + ', Netherlands'" 
                v-bind:text="
                'Graduated with an average grade of 8/10 (' + Link('https://www.studyinholland.nl/life-in-holland/dutch-grading-system', 'grading system') + ')' + '<br>' + 
                'Erasmus+ CEFR English test score C1: Effective operational proficiency or advanced.'" 
                time="AUGUST 2014 - JUNE 2018" duration="4 year course"/>

                <ResumeEntry id="3" title="VWO wiskunde B+T (mathematics course)" 
                v-bind:location="'Utrecht University ('+ Link('http://www.boswell-beta.nl/en/', 'Boswell') + '), Netherlands'" 
                v-bind:text="'Graduated with a 9.3/10 (' + Link('https://www.studyinholland.nl/life-in-holland/dutch-grading-system', 'grading system') + ')'" 
                time="JANUARY 2019 - APRIL 2019" duration="4 month course"/>

                <ResumeEntry id="4" title="Higher General Continued Education (havo)" 
                v-bind:location="'Veenlanden College, Netherlands'" 
                v-bind:text="''" 
                time="AUGUST 2011 - JULY 2014" duration="3 year course"/>
            </div>
        </div>
    </div>
</template>

<script>
import ResumeEntry from './ResumeEntry.vue'

export default {
  name: 'ResumeSection',
  components: {
    ResumeEntry,
  },
  methods: {
    Link(url, text)
    {
        return "<a href='" + url + "' target='_blank'> " + text + "</a>";
    }
  }
}
</script>

<style>
</style>