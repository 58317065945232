<script>
export default {
  name: 'NavbarSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*---------------------------------------------------
	3.1 Topbar
---------------------------------------------------*/

#section-topbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	overflow: visible;
	font: normal 16px/24px  'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#section-topbar a {
  color: #fff;
}

#topbar-inner {
	background: #111;
	line-height: 0;
	text-align: center;
}

/*
	Top Bar

	By default, top navigation bar uses "fixed" position
*/
#section-topbar ul#nav {
	display: inline-block;
	position: relative;
	margin: 0;
	padding: 0;

	border-right: 1px solid #222;

	/* For IE, the outcast */
	zoom:1;
	*display: inline;
}

#section-topbar ul#nav > li {
	display: block;
    float: left;
    padding: 0;
}

#section-topbar ul#nav a {
  display: block;
  font: normal 11px/18px "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  padding: 18px 20px 18px 20px;
  border-left: 1px solid #222;
  background: #111;
}

#section-topbar ul#nav a:hover {
  background: #2c3e50;
  text-decoration: none;
  border-left: 1px solid #111;
}

#section-topbar ul#nav .active a {
	background: #44bbaa;
	border-left: 1px solid #111;
}

#section-topbar ul#nav .active a:hover {
	background: #44bbaa;
}

#section-topbar i {
	font-size: 25px;
}
/* --- */
</style>

<template>
  <div id="section-topbar">
        <div id="topbar-inner">
            <div class="container">
                <ul id="nav" class="nav">
                    <li class="menu-item"><a href="#about" title="About"><i class="icon-user"><h6 class="special">About</h6></i></a></li>
                    <li class="menu-item"><a href="#resume" title="Resume"><i class="icon-file"><h6 class="special">Resume</h6></i></a></li>
                    <li class="menu-item"><a href="#projects" title="Work"><i class="icon-briefcase"><h6 class="special">Work</h6></i></a></li>
                    <li class="menu-item"><a href="#contact" title="Contact"><i class="icon-envelope"><h6 class="special">Contact</h6></i></a></li>
                </ul>

                <!--<div class="row">
                    <div class="dropdown">
                        <ul id="nav" class="nav">
                            <li class="menu-item"><a class="smoothScroll" href="#about" title="About"><i class="icon-user"><h6 class="special">About</h6></i></a></li>
                            <li class="menu-item"><a class="smoothScroll" href="#resume" title="Resume"><i class="icon-file"><h6 class="special">Resume</h6></i></a></li>
                            <li class="menu-item"><a class="smoothScroll" href="#projects" title="Work"><i class="icon-briefcase"><h6 class="special">Work</h6></i></a></li>
                            <li class="menu-item"><a class="smoothScroll" href="#contact" title="Contact"><i class="icon-envelope"><h6 class="special">Contact</h6></i></a></li>
                        </ul>
                    </div>

                    <div class="clear"></div>
                </div>-->
            </div>

            <div class="clear"></div>
        </div>
    </div>
</template>