<style scoped>
#footwrap {
	background: #2f2f2f;
	padding-top: 50px;
	padding-bottom: 50px;
}

#footwrap p {
	color: white;
}

#footwrap h5 {
	color: white;
}

#footwrap t {
	font-weight: 700;
}

#footwrap i {
	padding-right: 8px;
	color: #1abc9c;
}
</style>

<template>
    <!--FOOTER DESCRIPTION -->
    <div id="footwrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-lg-offset-1">
                    <h5><b>CONTACT</b></h5>
                </div>
                <div class="col-lg-6">
                    <p style="font-weight: 700; margin-bottom: 0px;">
                        Email
                    </p>
                    <p>
                        mai<!---->l@dav<!---->idsche<!---->p.co<!---->m <br />
                    </p>
                    <p style="font-weight: 700; margin-bottom: 0px;">
                        Phone
                    </p>
                    <p>
                        +31 6 1<!---->30<!---->9 078<!---->4 <br />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ContactSection',
}
</script>

<style>
</style>