<template>
    <div v-bind:class="getOffset()" id="no_padding">
        <div class="col-lg-9">
            <span v-if="type=== 'video'" v-html="getVideo(this.url)"></span>
            <span v-if="type=== 'image'" v-html="getImage(this.url)"></span>
        </div>
        <div class="col-lg-3">
            <p><b> <span v-html="title"></span> <br /> </b> </p>
            <p style="color: #95a5a6;margin-bottom:0px;">
                <span v-html="text"></span>
            </p>
            <p style="padding:0px;margin:0px;color: #95a5a6;font-size:12px;">
                <i class="icon-pushpin"></i>{{date}}
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ResumeSection',
  props: {
    id: Number,
    type: String,
    title: String,
    text: String,
    url: String,
    date: String
  },
  methods: {
    getOffset() {
        if (this.id == 0) return "col-lg-12";
        else return "col-lg-12 col-lg-offset-3";
    },
    getVideo(url) {
        return '<div class="video-container"><iframe src=' + url + ' frameborder="0" allowfullscreen></iframe></div>'
    },
    getImage(url) {
        return '<img src="' + url + '" style="max-width:100%;max-height:auto;">';
    }
  }
}
</script>

<style>
#no_padding {
    padding: 0px;
    padding-bottom: 25px;
}
</style>