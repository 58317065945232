<style scoped>
/* Intro Wrap */
#intro {
	background: #2c3e50;
	padding-top: 60px;
	padding-bottom: 60px;
	color: white;
} 

#intro h5, p {
	color: white;
}

#intro i {
	color: white;
	font-size: 20px;
	padding-right: 8px;
	vertical-align: middle;
}
/* --- */
</style>

<template>
  <div id="intro">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-lg-offset-1">
                    <h5><b>ABOUT</b></h5>
                </div>
                <div class="col-lg-6">
                    <p>
                        From working on a <a href="https://www.taleworlds.com/en/games/bannerlord" target="_blank">AAA title</a> at <a href="https://www.taleworlds.com/" target="_blank">TaleWorlds Entertainment</a> 
                        to working on applied games at <a href="https://www.gainplaystudio.com/" target="_blank">GainPlay Studio</a>, I've done a wide range of projects already.
                        <br>
                        <br>
                        I'm studying MSc Autonomous Systems at Denmark Technical University, being an Eindhoven University of Technology (BSc Computer Science), Breda University (International Game Architecture and Design), and Grafisch Lyceum Utrecht (Game Development) alumnus.
                        <br>
                        <br>
                        Experience in programming in different languages (such as C++, C# and Java), writing in small and large codebases (such as the Mount & Blade II game engine).
                        <br>
                        <br>
                        View my skills and projects below to learn more about me.
                    </p>
                </div>
                <div class="col-lg-3">
                    <p style="font-size: 12px">
                        <a href="resources/resume_david_van_scheppingen.pdf" target="_blank"><i class="icon-file"></i></a>
                        <a href="resources/resume_david_van_scheppingen.pdf" target="_blank"> DOWNLOAD PDF</a>
                        <br>
                        <a href="https://www.linkedin.com/in/davidschep/" target="_blank"><i class="icon-linkedin"></i></a>
                        <a href="https://www.linkedin.com/in/davidschep/" target="_blank"> LINKEDIN</a>
                    </p>
                    <div class="profile-photo">
                        <img src="assets/img/me_512.jpg" alt="Profile Picture" style="width:256px;height:212px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AboutSection',
}
</script>