<script>
export default {
  name: 'HeaderSection',
}
</script>

<style scoped>
/* Header Wrap */
#headerwrap {
	background: url(../assets/img/header-bg.jpg) no-repeat center top;
	margin-top: 60px;
	padding-top:140px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	min-height: 500px;
	width: 100%;
	
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#headerwrap h1 {
	font-size: 62px;
	color: white;
}

#headerwrap h3 {
	font-size: 20px;
	color: white;
}
/* --- */
</style>

<template>
    <div id="headerwrap">
        <div class="container">
            <div class="row centered">
                <div class="col-lg-12">
                    <h1>David van Scheppingen</h1>
                    <h3>Software Engineer | mai<!---->l@da<!---->vidschep.<!---->com | +31 6 <!---->130<!---->9 07<!---->84 </h3>
                </div>
            </div>
        </div>
    </div>
</template>