<style scoped>
#skillswrap {
	background: #2c3e50;
	padding-top: 60px;
	padding-bottom: 60px;
	color: white;
}

#skillswrap h5, p {
	color: white;
}

</style>

<template>
    <!--SKILLS DESCRIPTION -->
    <div id="skillswrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-lg-offset-1">
                    <h5><b>SKILLS</b></h5>
                </div>
                <div class="col-lg-9">
                    <p>
                        <b>Programming languages:</b>
                        <br>
                        C | C++ | C# | Java | JavaScript | HTML & CSS.
                        <br>

                        <b>Software:</b>
                        <br>
                        Unity3D | Unreal Engine | Android Studio.
                        <br>
                        Adobe Photoshop | Maya, 3DSMax and Blender.
                        <br>
                        Source control tools like Perforce, PlasticSCM, SourceTree and GitHub | the git command line.
                        <br>

                        <b>Experience in:</b>
                        <br>
                        Game, level and interaction design | setting up testing facilities (including developing data analytics tools).
                        <br>
                        Networking in games | prototyping of components and new ideas
                        <br>
                        MySql (with C#, Java & PHP). | working in and on custom game engines.
                        <br>
                        Agile software development methods. | Optimizing algorithms and software components.
                    </p>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SkillsSection',
}
</script>

<style>
</style>