<template>
    <div v-bind:class="getOffset()" id="no_padding">
        <div class="col-lg-8">
            <p style="color: #34495e;font-weight: 700;margin-bottom: 0px;">
                {{title}}
            </p>
            <p style="margin-bottom:10px;">
                <span v-html="location"></span>
            </p>
            <p style="color: #95a5a6;margin-bottom: 0px;">
                <span v-html="text"></span>
            </p>
        </div>
        <div class="col-lg-4">
            <p style="font-size: 12px;">
                {{time}}
                <br>
                {{duration}}
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ResumeEntry',
  props: {
    id: Number,
    title: String,
    location: String,
    text: String,
    time: String,
    duration: String
  },
  methods: {
    getOffset() {
        if (this.id == 0) return "col-lg-9";
        else return "col-lg-9 col-lg-offset-3";
    }
  }
}
</script>

<style>
#no_padding {
    padding: 0px;
}
</style>