<template>
  <body data-spy="scroll" data-offset="0" data-target="#nav">
    <Navbar/>

    <Header/>

    <section id="about" name="about"></section>
    <About/>

    <section id="resume" name="resume"></section>
    <Resume/>

    <Skills/>

    <section id="projects" name="projects"></section>
    <Projects/>

    <section id="contact" name="contact"></section>
    <Contact/>
    </body>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Header from './components/Header.vue'
import About from './components/About.vue'
import Resume from './components/Resume.vue'
import Skills from './components/Skills.vue'
import Projects from './components/Projects.vue'
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'davidschep',
    // unfortunately it seems that the index.html file has to be updated for google to recognize changes
    meta: [
      { name: 'description', content: 'Software Engineer | mail@davidschep.com | Eindhoven University of Technology, former Mount & Blade dev, Breda University and GLU alumnus | David van Scheppingen'}
    ]
  },
  components: {
    Navbar,
    Header,
    About,
    Resume,
    Skills,
    Projects,
    Contact,
  },
  mounted() {
  }
}
</script>

<style>
@import './assets/css/bootstrap.css';
/*@import './assets/css/main.css';*/
@import './assets/css/font-awesome.min.css';
@import 'https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic';
@import 'https://fonts.googleapis.com/css?family=Raleway:400,300,700,300bold,400bold,700bold';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 95px;
 }

/* HoverColor button (222=white) (f85c37=red) (2c3e50=blue) (111=black) (44bbaa=lightblue)*/
.background-blue
{
	background: #2c3e50;
}
.background-lightblue
{
	background: #516c86;
}
.white-text
{
	color: white;
}

body {
  background-color: #f2f2f2;
    font-family: "Lato";
    font-weight: 300;
    font-size: 16px;
    color: #555;

    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

/* Titles */
h1{
	font-family: "Raleway";
    font-weight: 300;
    color: #333;
    word-wrap:break-word;
}

h2, h3, h4, h5, h6 {
    font-family: "Raleway";
    font-weight: 300;
    color: #333;
}

h6.special { 
	font-family: "Raleway";
    font-weight: 300;
    color: #ffffff;
}
/* --- */

/* Paragraph & Typographic */
p {
    line-height: 28px;
    margin-bottom: 25px;
}

.centered {
    text-align: center;
}
/* --- */

/* Links */
a {
    color: #f85c37;
    word-wrap: break-word;

    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

a:hover,
a:focus {
    color: #7b7b7b;
    text-decoration: none;
    outline: 0;
}

a:before,
a:after {
    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

 hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
/* --- */

/* Buttons */
button {
	color: white;
    background: #2c3e50;
    word-wrap: break-word;

    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

button:hover {
    color: #7b7b7b;
    text-decoration: none;
    outline: 0;
}

button:before,
button:after {
    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

.selected-button
{
	background: #f85c37;
}
/* --- */

/*---------------------------------------------------
	SECTION WRAPS
---------------------------------------------------*/

/* Global Values */
.desc {
	padding-top: 50px;
}

.desc p {
	color: #2f2f2f;
}

.desc t {
	color: #34495e;
	font-weight: 700;
}

.desc imp {
	padding: 6px;
	color: white;
	background: #34495e;
}

.desc more {
	color: #95a5a6;
}
/* --- */
</style>