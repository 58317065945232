<template>
    <!--PORTFOLIO DESCRIPTION -->
    <div class="container desc">
        <div class="row" id="commercial_projects">
            <div class="col-lg-12">
                <h5><b>COMMERCIAL PROJECTS</b></h5>
                <br>
            </div>

            <ProjectEntry id=0 type="video" title="Mount and Blade 2: Bannerlord,<br>TaleWorlds Entertainment" 
            v-bind:text="'Mount & Blade II: Bannerlord is the eagerly awaited sequel to the acclaimed medieval combat simulator and role-playing game Mount & Blade: Warband. Set 200 years before, it expands both the detailed fighting system and the world of Calradia. Bombard mountain fastnesses with siege engines, establish secret criminal empires in the back alleys of cities, or charge into the thick of chaotic battles in your quest for power.' + Link('http://store.steampowered.com/app/261550/Mount__Blade_II_Bannerlord/', 'click here to go to the Steam page')" 
            url="https://www.youtube.com/embed/M_PSRDwORDQ" date="Mar. 2020"/>

            <ProjectEntry id=0 type="video" title="DigestInn,<br>GainPlay Studio" 
            v-bind:text="'Digest Inn is a game that assists the dietitian and their clients to reach treatment goals, by increasing their clients’ treatment adherence, through motivating them with playful interaction. ' + Link('http://www.digestinn.com/', 'Click here to go to the DigestInn site. ')" 
            url="https://www.youtube.com/embed/8t4-N4lQIPw" date="Aug. 2017"/>

            <!--<ProjectEntry id=0 type="image" title="Working with Yousician,<br>Unite 2017" 
            v-bind:text="'I got the chance to work with Yousician, and represent them at the Unite 2017 event. <br>With the team we showed off the technicalities of ' + Link('https://yousician.com/', 'their app') + ', that teaches upcoming musical talent how to play their instruments." 
            url="assets/img/unite2017banner.jpg" />-->

            <!--
            ------- MORE DETAILED PROJECT VIEW IN DEVELOPMENT -------

            <div class="col-lg-9">
                <button type="button" class="background-blue">Video</button>
                <button type="button" class="background-lightblue">My role</button>
            </div>
            <div class="col-lg-9">
                <div class="video-container">
                    <iframe src="https://www.youtube.com/embed/M_PSRDwORDQ" frameborder="0" allowfullscreen></iframe>
                </div>
                <br>
            </div>
            <div class="col-lg-3">
                <p><b>Mount and Blade 2: Bannerlord,<br>TaleWorlds Entertainment</b></p>
                <p>Mount & Blade II: Bannerlord is the eagerly awaited sequel to the acclaimed medieval combat simulator and role-playing game Mount & Blade: Warband.</p>
            </div>


            <div class="col-lg-9">
                <button type="button" class="background-lightblue">Video</button>
                <button type="button" class="background-lightblue">My role</button>
                <button type="button" class="background-blue">Technical</button>
                <button type="button" class="background-lightblue">Code</button>
            </div>
            <div class="col-lg-9">
                <div class="background-blue">
                    <p style="color:white">asdf<br>asfasdfasdfasdf<br>asdfasdf<br>asfasdfasdfasdf<br>asdfasdf<br>asfasdfasdfasdf<br>asdfasdf<br>asfasdfasdfasdf<br>asdfasdf</p>
                </div>
                <br>
            </div>
            <div class="col-lg-3">
                <p><b>DigestInn,<br>GainPlay Studio</b></p>
                <p>Aasdfasdfas asdfasd  a sdfasdasdf  fsaddf.</p>
            </div>
            -->

            <!-- <commercial projects go here and are fed in through site builder> -->
        </div>

        <hr>
        <div class="row" id="personal_projects">
            <div class="col-lg-12">
                <h5><b>PERSONAL PROJECTS</b></h5>
                <br>
            </div>

            <!--<ProjectEntry id=0 type="video" title="" 
            v-bind:text="" 
            url="" />-->

            <ProjectEntry id=0 type="image" title="brt - a ray trace renderer" 
            v-bind:text="'This ray tracer is an ongoing project that I started with late 2018.' + '<br />' + Link('https://github.com/davidschep/brt', 'Click here to go to the GitHub page.')" 
            url="assets/img/brt.gif" date="Dec. 2018"/>

            <ProjectEntry id=0 type="image" title="2D game framework" 
            v-bind:text="'ODTUENGINE is a simple \'game engine/framework\' written in C++ with the help of OpenGL. Full credits and tools used can be found in the reflection (link below).<br/>As mentioned in the reflection there are a lot of things I\'d still like to change and improve, so especially the Game folder in the project doesn\'t reflect my coding standard, and was written to meet a deadline. See the following links for the ' + Link('https://github.com/davidschep/ODTUENGINE', 'GitHub page') + ' , and the '  + Link('resources/odtuengine_reflection.pdf', 'reflection (January)') + '.'" 
            url="assets/img/ODTUENGINEbanner.jpg" date="Dec. 2017"/>
        </div>
        
        <hr>
        <div class="row" id="hackathon_projects">
            <div class="col-lg-12">
                <h5><b>GAMEJAMS & HACKATHONS</b></h5>
                <br>
            </div>

            <ProjectEntry id=0 type="video" title="Global Game Jam 2019,<br>(theme: what home means to you)" 
            v-bind:text="'This was a gamejam I did at Breda University, where I teamed up with 2 other programmers, 3 artists, and 2 (audio) designers. Our goal was to make a fun single player game, in the 48 hour time window. ' + Link('https://globalgamejam.org/2019/games/hausmaus', 'Read more about the game on the ggj website.')" 
            url="https://www.youtube.com/embed/US900MQ57hI" date="Jan. 2019"/>

            <ProjectEntry id=0 type="video" title="Global Game Jam 2017,<br>(theme: Waves)" 
            v-bind:text="'The theme of the Global Game Jam was waves, so the idea we came up with was that the player had to lure in aliens with his frequency wave transmitter. I think it\'s a pretty cool game for a 48 hour game jam, the only thing I would have added, if there was more time, is a better tutorial system. The game is kind of complicating in its current state. <br /> The game was made in Unity3D by me, another programmer, and a 2D artist.'" 
            url="https://www.youtube.com/embed/9yseM3k3mEA" date="Jan. 2017"/>

            <ProjectEntry id=0 type="video" title="Kinect 2.0 boxing game,<br>hackathon prototype" 
            v-bind:text="'The game was a quick Kinect 2.0 prototype. It\'s idea is simple, punch & kick each other until you win. <br> The game calculates damage based on angle, speed, approach of attack and the ability of the opponent to block your attack.'" 
            url="https://www.youtube.com/embed/2AV4QGq17nQ" date="Jan. 2017"/>

            <ProjectEntry id=0 type="video" title="Velo Protector - Mobile Tour de France game,<br>Game Jam" 
            v-bind:text="'This game was build in one of the first gamejams I\'ve participated in. The objective is to protect the bikers from rubble thrown on the road, and spectators trying to cross. After development we got the chance to show this game to King Willem Alexander (King of the Netherlands), and the Minister for Education. ' + Link('resources/VeloProtector_9-4-2015.apk', 'You can download the APK here.')" 
            url="https://www.youtube.com/embed/t0TBYXTcsec" date="Apr. 2015"/>
        </div>
    </div>
</template>

<script>
import ProjectEntry from './ProjectEntry.vue'

export default {
  name: 'ProjectsSection',
  components: {
    ProjectEntry,
  },
  methods: {
    // TODO: this function is in Resume.vue too, have one tools file for it or something
    Link(url, text)
    {
        return "<a href='" + url + "' target='_blank'> " + text + "</a>";
    }
  }
}
</script>

<style>
</style>